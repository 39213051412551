(function(app, $) {
	var $cache = {};
	var klarnaPaymentsUrls = window.KlarnaPaymentsUrls || {};
	var klarnaPaymentsObjects = window.KlarnaPaymentsObjects || {};

	if (($('#klarna-payments-container').length || !!$('.js-checkout_summary').length) && klarnaPaymentsObjects.clientToken) {
		var script = document.createElement('script');
		script.src = 'https://x.klarnacdn.net/kp/lib/v1/api.js';
		document.body.append(script);

		window.klarnaAsyncCallback = function() {
			app.components.global.klarnapayments.init();
		};
	}

	function initializeCache() {
		$cache = {
			containerName: '#klarna-payments-container',
			checkoutForm: $('.js-checkout_form'),
			stepSubmitButton: $('[name=dwfrm_checkout_submitStep]')
		};
	}

	function InitWidget() {
		try {
			if ($($cache.containerName).length > 0) {
				Klarna.Payments.init({
					client_token: klarnaPaymentsObjects.clientToken
				});
			}
		} catch (error) {
			return;
		}
	}

	function LoadingWidget() {
		if ($($cache.containerName).length > 0) {
			Klarna.Payments.load({
				container: $cache.containerName,
				payment_method_category: '' + app.paymentInfo.klarnaPayment.selectedType
			}, function() {});
		}
	}

	function authorize(params) {
		var deferred = $.Deferred();
		Klarna.Payments.authorize({
			payment_method_category: app.paymentInfo.klarnaPayment.selectedType,
			auto_finalize: false
		},
		params,
		function(res) {
			if (res.finalize_required) {
				app.paymentInfo.klarnaPayment.finalizeRequire = res.finalize_required;
			}

			if (res.approved && (res.authorization_token || res.finalize_required)) {
				// TODO: Can be rewritten using $.ajax. Current implementation -from integration cartridge
				var xhr = new XMLHttpRequest();
				xhr.open('POST', klarnaPaymentsUrls.saveAuthURL, true);

				xhr.setRequestHeader('Content-type', 'application/json; charset=utf-8');
				xhr.setRequestHeader('X-Auth', res.authorization_token);
				xhr.setRequestHeader('Finalize-Required', res.finalize_required);

				xhr.onreadystatechange = function() {
					if (xhr.readyState === XMLHttpRequest.DONE && xhr.status === 200) {
						deferred.resolve($cache.stepSubmitButton);
					}
				};
				xhr.send();
			} else {
				deferred.reject();
			}
		});

		return deferred.promise();
	}

	function finalize(params) {
		var deferred = $.Deferred();
		Klarna.Payments.finalize({
			payment_method_category: app.paymentInfo.klarnaPayment.selectedType
		},
		{},
		function(res) {
			if (res.approved) {
				// TODO: Can be rewritten using $.ajax. Current implementation -from integration cartridge
				var xhr = new XMLHttpRequest();
				xhr.open('POST', klarnaPaymentsUrls.saveAuthURL, true);
				xhr.setRequestHeader('Content-type', 'application/json; charset=utf-8');
				xhr.setRequestHeader('X-Auth', res.authorization_token);
				xhr.onreadystatechange = function() {
					if (xhr.readyState === XMLHttpRequest.DONE && xhr.status === 200) {
						deferred.resolve($cache.stepSubmitButton);
					}
				};
				xhr.send();
			} else {
				deferred.reject();
			}
		});

		return deferred.promise();
	}

	function reInitWidget(changingCountry) {
		var librarylink;
		var libraryClientID;
		var $placementWrapper = $('.js-onsite-widget');
		var euLibrary = app.preferences.OnSiteMessageConfiguration.EU;
		var naLibrary = app.preferences.OnSiteMessageConfiguration.US;

		if (changingCountry === 'US') {
			librarylink = naLibrary.link;
			libraryClientID = naLibrary.clientID;
		} else {
			librarylink = euLibrary.link;
			libraryClientID = euLibrary.clientID;
		}

		$placementWrapper.find('script').remove();
		$placementWrapper.find('iframe').remove();

		var s = document.createElement('script');
		s.async = true;
		s.src = librarylink;
		s.setAttribute('data-client-id', libraryClientID);
		document.getElementsByClassName('js-onsite-widget')[0].appendChild(s);
	}

	function siteMessageUpdate(data) {
		if (data) {
			var $klarnaTag = $('klarna-placement');
			var changingCountry = data.locale.value.split('-')[1];
			var dataFields = Object.keys(data);

			for (var i = 0, length = dataFields.length; i < length; i++) {
				$klarnaTag.attr(data[dataFields[i]].target, data[dataFields[i]].value);
			}

			window.KlarnaOnsiteService.loaded = false;
			reInitWidget(changingCountry);
		} else {
			window.KlarnaOnsiteService = window.KlarnaOnsiteService || [];
			window.KlarnaOnsiteService.push({ eventName: 'refresh-placements' });
		}
	}

	app.components = app.components || {};
	app.components.global = app.components.global || {};
	app.components.global.klarnapayments = {
		init: function() {
			initializeCache();
			InitWidget();
			LoadingWidget();
		},
		authorize: authorize,
		finalize: finalize,
		siteMessageUpdate: siteMessageUpdate
	};
})((window.app = window.app || {}), jQuery);
